<template>
    <div class="privacy-policy-item my-1">
        <div class="privacy-policy-item-title">
            <div class="privacy-policy-item-title-text">
                {{ $t(item.title) }}
            </div>
            <img 
                v-for="(url, i) in item.imgUrls"
                :key="i"
                :src="url"
                class="item-image"
            />
        </div>
        <div class="privacy-policy-item-content">
            <span v-html="$t(item.content)"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PrivacyPolicyItem',
    props: {
        item: Object,
    },

}
</script>

<style scoped>

.privacy-policy-item-title {
    display: flex;
    align-items: center;
}
.privacy-policy-item-title .privacy-policy-item-title-text {
    font-weight: bold;
}

.privacy-policy-item-content {
    font-size: 15px;
}

.privacy-policy-item-content span::v-deep table {
    border-collapse: collapse;
}
.privacy-policy-item-content span::v-deep th,
.privacy-policy-item-content span::v-deep td{
    width: 100px;
    height: 50px;
    text-align: center;
    border: 1px solid #000;
    
    vertical-align: top;	/* 위 */
    vertical-align: bottom;   /* 아래 */
    vertical-align: middle;   /* 가운데 */
}
.privacy-policy-item-content span::v-deep th {
    font-weight: bold;
}

.item-image {
    width: 40px;
}
</style>